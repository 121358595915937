import AccordionComponent from "./AccordionComponent";
import BackgroundImage from "./BackgroundImage";
import BasicForm from "./BasicForm";
import BasicIntroForm from "./BasicIntroForm";
import BasicCard from "./Cards/BasicCard";
import CypherCard from "./Cards/CypherCard";
import DownloadCard from "./Cards/DownloadCard";
import ImageCard from "./Cards/ImageCard";
import ModalLinkCard from "./Cards/ModalLinkCard";
import OdsCard from "./Cards/OdsCard";
import OrderedCard from "./Cards/OrderedCard";
import Chart from "./Chart";
import DepartmentsBar from "./DepartmentsBar";
import FeatureComponent from "./FeatureComponent";
import FileItem from "./FileItem";
import HeroSlide from "./HeroSlide";
import LandingProgramNavigationTab from "./LandingProgramNavigationTab";
import LocationComponent from "./LocationComponent";
import NewQuoteTestimonial from "./NewQuoteTestimonial";
import OdsCardRows from "./OdsCardRows";
import ProgramListTabComponent from "./ProgramListTabComponent";
import ProgramNavigationTab from "./ProgramNavigationTab";
import TabsComponent from "./TabsComponent";
import VideoComponent from "./VideoComponent";
import QuoteTestimonial from "../components/QuoteTestimonial";

export default {
	AccordionComponent,
	BackgroundImage,
	BasicCard,
	BasicForm,
	BasicIntroForm,
	Chart,
	CypherCard,
	DownloadCard,
	DepartmentsBar,
	FeatureComponent,
	FileItem,
	HeroSlide,
	ImageCard,
	LandingProgramNavigationTab,
	LocationComponent,
	ModalLinkCard,
	NewQuoteTestimonial,
	OdsCard,
	OdsCardRows,
	OrderedCard,
	ProgramListTabComponent,
	ProgramNavigationTab,
	QuoteTestimonial,
	TabsComponent,
	VideoComponent,
};
